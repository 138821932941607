import React from 'react'
import NavBar from '../components/NavBar'
import Footer from '../components/Footer'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col';
import 'bootstrap/dist/css/bootstrap.min.css';
import Image from 'react-bootstrap/Image';
import ContactUsInfo from '../components/ContactUsInfo';
import TrackOrdersSection from '../components/TrackOrdersSection';

import hl_image from '../images/justlogo.png'
import hl_image2 from '../images/carnight2.jpg'

function UrgentDelivery() {
  return (
    <Container fluid>
      <Row>
        <NavBar></NavBar>
        <div className='urgentdelivery-banner d-flex justify-content-center align-items-center'>
          <div className='text-center'>
            <div className='h2' style={{ color: "white" }}>Urgent Delivery</div>
          </div>
        </div>
        <div className='bg-white d-flex'>
          <Container>
            <Row  className="justify-content-lg-center align-items-center" fluid="lg">
              <Col className='p-3'>
                  <p className='text-primary fw-bold'  md>Why Choose Us?</p>
                  <p className='h1 fw-bold'>Urgent Delivery</p>
                  <p className='lead'>Medfreight will be your trusted partner for urgent delivery services. We understand that time-sensitive shipments
                    require swift, reliable, and secure transportation. Our urgent delivery website offers a range of expedited shipping
                    options tailored to meet your urgent delivery needs. Whether it's critical documents, medical supplies, perishable goods,
                    or time-critical packages, we ensure prompt and efficient delivery</p>
              </Col>
              <Col className='p-0' md>
                  <Image src={hl_image2} fluid width="100%" />
              </Col>
            </Row>
            <Row className="justify-content-md-center pt-5" fluid="lg">
              <Row>
                <Col lg={4}>
                  <Row className='align-items-center'>
                    <Col sm={2}>
                      <Image src={hl_image} width="50px"></Image>
                    </Col>
                    <Col sm={10}>
                      <p className='h6'>Express Delivery:</p>
                    </Col>
                  </Row>
                  <Col>
                    <p >Need immediate delivery within the same day? Our same-day delivery service is designed to fulfill
                      urgent requirements promptly. We offer reliable and efficient delivery within the shortest possible
                      timeframe, allowing you to meet critical deadlines, handle emergency situations, and provide exceptional
                      service to your customers.</p>
                  </Col>
                </Col>
                <Col lg={4}>
                  <Row className='align-items-center'>
                    <Col sm={2}>
                      <Image src={hl_image} width="50px"></Image>
                    </Col>
                    <Col sm={10}>
                      <p className='h6'>Same-Day Delivery:</p>
                    </Col>
                  </Row>
                  <Col>
                    <p >Need immediate delivery within the same day? Our same-day delivery service is designed to fulfill urgent
                      requirements promptly. We offer reliable and efficient delivery within the shortest possible timeframe,
                      allowing you to meet critical deadlines, handle emergency situations, and provide exceptional service
                      to your customers.</p>
                  </Col>
                </Col>
                <Col lg={4}>
                  <Row className='align-items-center'>
                    <Col sm={2}>
                      <Image src={hl_image} width="50px"></Image>
                    </Col>
                    <Col sm={10}>
                      <p className='h6'>Next-Day Delivery:</p>
                    </Col>
                  </Row>
                  <Col>
                    <p >For time-sensitive shipments that require delivery by the next business day, our next-day delivery
                      service is the perfect solution. We optimize logistics operations to ensure efficient pickup,
                      transportation, and delivery. Rest assured that your urgent packages will arrive at their destinations
                      promptly and securely.</p>
                  </Col>
                </Col>
              </Row>
            </Row>
          </Container>
        </div>
        <ContactUsInfo></ContactUsInfo>
        <TrackOrdersSection></TrackOrdersSection>
        <Footer></Footer>
      </Row>
    </Container>
  )
}

export default UrgentDelivery