import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import 'bootstrap/dist/css/bootstrap.min.css';
import Image from 'react-bootstrap/Image';

import hl_image from '../images/vanside.jpg'
import hl_image2 from '../images/justlogo.png'

import NavBar from '../components/NavBar';
import ContactUsInfo from '../components/ContactUsInfo';
import TrackOrdersSection from '../components/TrackOrdersSection';
import Footer from '../components/Footer';

function HealthcareLogistics() {
    return (
        <Container fluid>
            <Row>
                <NavBar></NavBar>
                <div className='healthcarelogi-banner d-flex justify-content-center align-items-center'>
                    <div className='text-center'>
                        <div className='h2 fw-bold' style={{ color: "white" }}>Healthcare Logistic</div>
                    </div>
                </div>

                <div className='bg-white d-flex'>
                    <Container>
                        <Row  className="justify-content-lg-center align-items-center" fluid="lg">
                            <Col lg={6} className='p-3'>
                                <p className='h5 text-primary' varient="primary">Why Choose Us?</p>
                                <p className='h1'>Healthcare Logistics</p>
                                <p  className='lead'>The importance of medical supplies is well known, especially during times of emergencies or healthcare crises. Using our dedicated team and specialized expertise, we make sure medical supplies, medicines, and healthcare equipment reach their destinations quickly and safely.
                                    Our healthcare urgent delivery service provides hospitals, clinics, and healthcare facilities with essential medical supplies, including personal protective equipment, test kits, medications, and medical devices.
                                    With Medfreight, you can always rely on reliable and time-sensitive transportation of urgent medical supplies.
                                    Our company offers a comprehensive range of healthcare logistics and transportation services within Auckland region including</p>
                            </Col>
                            <Col className='p-0' md>
                                <Image src={hl_image} fluid width="100%"></Image>
                            </Col>
                        </Row>
                        <Row className="justify-content-md-center mt-3" fluid="lg">
                            <Row>
                                <Col lg={6}>
                                    <Row className='align-items-center'>
                                        <Col sm={1}>
                                            <Image src={hl_image2} width="50px"></Image>
                                        </Col>
                                        <Col sm={11}>
                                            <p className='h6 fw-bold'>On-Demand and Emergency Delivery:</p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <p >We offer on-demand and emergency healthcare delivery services to address urgent needs
                                            and unforeseen situations promptly. Our dedicated team is available around the clock to respond to critical
                                            requirements, providing swift and reliable delivery solutions.</p>
                                    </Row>
                                </Col>
                                <Col lg={6}>
                                    <Row className='align-items-center'>
                                        <Col sm={1}>
                                            <Image src={hl_image2} width="50px"></Image>
                                        </Col>
                                        <Col sm={11}>
                                            <p className='h6 fw-bold'>E-commerce Fulfillment for Medical Supplies:</p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <p>In today's digital age, e-commerce is becoming increasingly important in the healthcare sector.
                                            Our healthcare delivery services include e-commerce fulfillment, allowing healthcare providers
                                            and patients to conveniently order medical supplies online and have them delivered to their doorstep.</p>
                                    </Row>
                                </Col>
                            </Row>
                        </Row>
                        <Row className="justify-content-md-center" fluid="lg">
                            <Row>
                                <Col lg={6}>
                                    <Row className='align-items-center'>
                                        <Col sm={1}>
                                            <Image src={hl_image2} width="50px"></Image>
                                        </Col>
                                        <Col sm={11}>
                                            <p className='h6 fw-bold'>Medical Equipment and Supplies Transportation:</p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <p >We offer specialized transportation services for medical equipment and supplies, ensuring that they
                                            are transported safely and securely.</p>
                                    </Row>
                                </Col>
                                <Col lg={6}>
                                    <Row className='align-items-center'>
                                        <Col sm={1}>
                                            <Image src={hl_image2} width="50px"></Image>
                                        </Col>
                                        <Col sm={11}>
                                            <p className='h6 fw-bold'>Pharmaceutical Logistics:</p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <p>Our team of experts is trained in the handling and transportation of pharmaceutical products,
                                            ensuring that they are delivered in optimal conditions.</p>
                                    </Row>
                                </Col>
                            </Row>
                        </Row>
                    </Container>
                </div>
                <ContactUsInfo></ContactUsInfo>
                <TrackOrdersSection></TrackOrdersSection>
                <Footer></Footer>
            </Row>
        </Container>
    )
}

export default HealthcareLogistics