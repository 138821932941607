import { useState,useRef } from 'react';
import { Form, Button } from 'react-bootstrap';
import emailjs from '@emailjs/browser';
import Modal from 'react-bootstrap/Modal';
const isEmail = (email) =>
/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
// import { Person } from 'react-bootstrap-icons';
// import { Envelope } from 'react-bootstrap-icons';
// import { Telephone } from 'react-bootstrap-icons';

function ContactForm() {
    const [from_name, setFrom_name] = useState('');
    const setEmail = (e) => {
        setValues((values) => ({ ...values, email: e.target.value }));
      };
    const [phone, setPhone] = useState('');
    const [comment, setComment] = useState('');
    const [show, setShow] = useState(false);
    const form = useRef();

    const [values, setValues] = useState({ email: "" });
    const [errors, setErrors] = useState({});
    
    const handleShow = () => setShow(true);
    const handleClose = () => {
        setShow(false);
        window.open("/",'_self'); //To navigate to home page
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        const errors = {};
        if (!isEmail(values.email)) {
            errors.email = "Please enter a valid email address";
        }
        setErrors(errors);

        if (!Object.keys(errors).length) {
            emailjs.sendForm('service_tldwcz4', 'template_j9xucrt', form.current, 'pMqa1rMozzsrSJFjG')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
            
            handleShow();
        }
    // Process the form data and calculate the shipping quote
    //const quote = calculateShippingQuote(origin, destination, weight, shippingMethod);
    
    };

    return (    
        <div className='quoteform d-flex flex-column justify-content-center'>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                <Modal.Title>Message Received</Modal.Title>
                </Modal.Header>
                <Modal.Body>Thank you for contacting Medfreight Logistics.<br></br> We will get back to you as soon as posiible.</Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                </Modal.Footer>
            </Modal>


            <div className='d-flex flex-column justify-content-center'>
                <p className='h1 p-3 text-center' style={{color:'white'}}>Get A Free Quote!</p>
                <p className='lead text-center ms-2 me-2' style={{color:'white'}}>Medfreight will be your trusted partner for urgent delivery services. </p>
                <Form ref={form} onSubmit={handleSubmit}>
                        {/* <Person size={30} style={{color:'#d9d9d9', position:'relative', top:'43px', right:'-420px'}}/>
                        <Envelope size={30} style={{color:'#d9d9d9', position:'relative', top:'99px', right:'-390px'}}/>
                        <Telephone size={29} style={{color:'#d9d9d9', position:'relative', top:'153px', right:'-360px'}}/> */}
                    <Form.Group className='d-flex justify-content-center'>
                        <Form.Control
                        name='from_name'
                        type="text"
                        placeholder="Full Name"
                        required={true}
                        value={from_name}
                        onChange={(e) => setFrom_name(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group className='d-flex justify-content-center'>
                        <Form.Control
                        name='email'
                        type="text"
                        placeholder="Email address"
                        required={true}
                        id="userEmail"
                        value={values.email}
                        onChange={setEmail}
                        />{" "}
                    </Form.Group>
                            {Object.entries(errors).map(([key, error]) => (
                                <span
                                key={`${error}`}
                                style={{
                                    fontWeight: "bold",
                                    color: "red"
                                }}
                                >
                                &nbsp;&nbsp;&nbsp;&nbsp; {error}
                                </span>
                            ))}
                    <Form.Group className='d-flex justify-content-center'>
                        <Form.Control
                        name='phone'
                        type="text"
                        placeholder="Phone number"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group className='d-flex justify-content-center'>
                        <Form.Control
                        name='comment'
                        type="text-area" 
                        rows="20" 
                        style={{height:'120px'}}
                        placeholder="Comment"
                        required={true}
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                        />
                    </Form.Group>
                        <Button variant="primary" style={{color:'white'}} type="submit">Get Quote </Button>
                </Form>
            </div>
        </div>
    )
}

export default ContactForm