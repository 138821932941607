import React from 'react'
import NavBar from '../components/NavBar'
import Footer from '../components/Footer'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col';
import 'bootstrap/dist/css/bootstrap.min.css';
import Image from 'react-bootstrap/Image';
import ContactUsInfo from '../components/ContactUsInfo';
import TrackOrdersSection from '../components/TrackOrdersSection'


import hl_image3 from '../images/carandskytower2.jpg'
import hl_image from '../images/justlogo.png'

function HealthcareServices() {
  return (
    <Container fluid>
      <Row>
        <NavBar></NavBar>
        <div className='healthcareservices-banner d-flex justify-content-center align-items-center'>
          <div className='text-center'>
            <div className='h2 fw-bold' style={{ color: "black" }}>Supply Chain <br/> & <br/> Warehousing Solutions</div>
          </div>
        </div>

        <div className='bg-secondary pb-5 d-flex'>
          <Container>
            <Row className="align-items-center">
              <Col  className='p-3' lg={6}>
                <p className='h5 text-primary' varient="primary">Why Choose Us?</p>
                <p className='h1'>Supply Chain & <br/> Warehousing Solutions</p>
                <p className='lead'>Our services cover a wide range of needs and are specialized in providing high-quality
                  services to the Healthcare industry. The utmost care and attention are paid to each shipment
                  at our company in order to provide excellent customer service. As part of our client-centered approach,
                  our team develops customized transportation solutions that address their specific needs. Our goal is
                  to provide seamless transportation service to our clients, starting with the moment their goods
                  are picked up and ending with the delivery of their goods.</p>
              </Col>
              <Col className='row  align-items-start ' lg={6}>
                <Image src={hl_image3} fluid width="300px"></Image>
              </Col>
            </Row>
            <Row className="justify-content-md-center pt-5" fluid="lg">
              {/*<Row>
                <Col lg={6}>
                  <Row className='align-items-center'>
                    <Col sm={2}>
                      <Image src={hl_image} width="50px"></Image>
                    </Col>
                    <Col sm={10}>
                      <p className='h6'>On-Demand Delivery:</p>
                    </Col>
                  </Row>
                  <Col>
                    <p >Our on-demand delivery service caters to your urgent needs anytime, even outside business hours.
                      Simply request a pickup, and our dedicated team will swiftly handle your shipment and deliver it
                      directly to the recipient. With our on-demand service, you can address unexpected requirements,
                      emergency situations, or last-minute changes seamlessly.</p>
                  </Col>
                </Col>
                <Col lg={6}>
                  <Row className='align-items-center'>
                    <Col sm={2}>
                      <Image src={hl_image} width="50px"></Image>
                    </Col>
                    <Col sm={10}>
                      <p className='h6'>Customization and Scalability:</p>
                    </Col>
                  </Row>
                  <Col>
                    <p >We understand all organizations have unique needs, especially the healthcare industry and face evolving
                      challenges. Our 3PL solutions are highly customizable, tailored to your specific requirements. Whether
                      you are a small clinic or a large healthcare system, our scalable services adapt to your growing needs,
                      ensuring flexibility and cost-effectiveness.</p>
                  </Col>
                </Col>
              </Row>*/}
            </Row>
            <Row className="justify-content-md-center pt-5" fluid="lg">
              <Row>
                <Col lg={6}>
                  <Row className='align-items-center'>
                    <Col sm={2}>
                      <Image src={hl_image} width="50px"></Image>
                    </Col>
                    <Col sm={10}>
                      <p className='h6'>Supply Chain and Warehousing solutions:</p>
                    </Col>
                    <Row>
                      <p >We are dedicated to delivering comprehensive supply chain and warehousing solutions
                        that optimize efficiency, reduce costs, and enhance customer satisfaction.</p>
                    </Row>
                    <Row>
                      <ul className='list-group list-group-services' style={{borderRadius:"0px"}}>
                        <li className='list-group-item'>Demand Planning and Forecasting</li>
                        <li className='list-group-item'>Supplier Management</li>
                        <li className='list-group-item'>Inventory Management</li>
                        <li className='list-group-item'>Returns Management</li>
                        <li className='list-group-item'>Reporting and Analytics</li>
                      </ul>
                    </Row>
                  </Row>
                </Col>
              </Row>
            </Row>
          </Container>
        </div>

        {/* <div className='bg-white pb-5 d-flex'>
          <Container>
            <Row className="align-items-center">
              <Col className='p-3' lg={6}>
                <p className='h5 text-primary' varient="primary">Why Choose Us?</p>
                <p className='h1'>Healthcare 3PL</p>
                <p className='lead'>offering end-to-end supply chain solutions tailored to the unique requirements
                  of the healthcare industry. We streamline logistics operations, optimize efficiency,
                  and ensure the reliable delivery of healthcare products.</p>
              </Col>
              <Col className='row  align-items-start ' lg={6}>
                <Image src={hl_image3} fluid width="300px"></Image>
              </Col>
            </Row>
            <Row className="justify-content-md-center mt-5" fluid="lg">
              <Row>
                <Col lg={6}>
                  <Row className='align-items-center'>
                    <Col sm={2}>
                      <Image src={hl_image} width="50px"></Image>
                    </Col>
                    <Col sm={10}>
                      <p className='h6'>Inventory Management:</p>
                    </Col>
                  </Row>
                  <Col>
                    <p>Efficient inventory management<br/>
                    It is critical for healthcare organizations. Our 3PL solutions provide real-time visibility into inventory
                      levels, expiration dates, and usage patterns. We employ advanced inventory tracking systems to help you
                      optimize stock levels, minimize waste, and ensure that critical supplies are always available when needed.</p>
                  </Col>
                </Col>
                <Col lg={6}>
                  <Row className='align-items-center'>
                    <Col sm={2}>
                      <Image src={hl_image} width="50px"></Image>
                    </Col>
                    <Col sm={10}>
                      <p className='h6'>Order Fulfillment:</p>
                    </Col>
                  </Row>
                  <Col>
                    <p >We simplify the order fulfillment process, allowing healthcare providers to focus on delivering quality care.
                      Our streamlined order management system enables easy online order placement, order tracking, and automated
                      notifications. We ensure accurate and timely order processing, ensuring that healthcare products reach their
                      destinations promptly.</p>
                  </Col>
                </Col>
              </Row>
            </Row>
            <Row className="justify-content-md-center" fluid="lg">
              <Row>
                <Col lg={6}>
                  <Row className='align-items-center'>
                    <Col sm={2}>
                      <Image src={hl_image} width="50px"></Image>
                    </Col>
                    <Col sm={10}>
                      <p className='h6'>Warehousing and Distribution:</p>
                    </Col>
                  </Row>
                  <Col>
                    <p >Our secure and compliant warehouses are specifically designed to meet the unique storage requirements
                      of healthcare products. We provide temperature-controlled environments, adherence to regulatory standards,
                      and comprehensive security measures to protect your valuable inventory. Our efficient distribution network
                      ensures reliable and timely delivery to healthcare facilities, pharmacies, and patients.</p>
                  </Col>
                </Col>
                <Col lg={6}>
                  <Row className='align-items-center'>
                    <Col sm={2}>
                      <Image src={hl_image} width="50px"></Image>
                    </Col>
                    <Col sm={10}>
                      <p className='h6'>Cold Chain Management:</p>
                    </Col>
                  </Row>
                  <Col>
                    <p >Temperature-sensitive pharmaceuticals, vaccines, and biologics require specialized handling and storage.
                      Our expertise in cold chain logistics ensures the integrity and efficacy of these products. We utilize
                      cutting-edge technology, industry best practices, and rigorous temperature monitoring to maintain optimal
                      conditions throughout the entire cold chain, from storage to transportation and final delivery.</p>
                  </Col>
                </Col>
              </Row>
            </Row>
          </Container>
        </div> */}
        <ContactUsInfo></ContactUsInfo>
        <TrackOrdersSection></TrackOrdersSection>
        <Footer></Footer>
      </Row>
    </Container>
  )
}

export default HealthcareServices