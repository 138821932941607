import React from 'react'
import Image from 'react-bootstrap/Image';
import cityImage from '../images/CorouselImage/bridge.jpg';
import roadImage from '../images/CorouselImage/cranes.jpg';
import truckImage from '../images/CorouselImage/skytowernight.jpg';
import Carousel from 'react-bootstrap/Carousel';
// import Button from 'react-bootstrap/Button';
//import './HeroSection.css';

import HightLightSection from '../components/HightLightSection';

function HeroSection() {
  return (
    <div className='testing p-0'>
    <HightLightSection></HightLightSection>
      <div className='carousel'>
          <Carousel className='ps-0 pe-0'>
            <Carousel.Item interval={3500} >
              <Image src={cityImage} fluid width="100%"></Image>
              <Carousel.Caption className='carousel-caption'>
                <h3 style={{color:"black"}}>Connecting Businesses, One Delivery at a Time</h3>
                {/* <Button href='bookacourier' variant="primary" size='lg'style={{color:"white"}}>Book A Courier</Button> */}
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item interval={3500}>
            <Image src={roadImage} fluid width="100%"></Image>
              <Carousel.Caption className='carousel-caption'>
                <h3>Delivering Solutions, Beyond Expectations</h3>
                {/* <Button href='bookacourier' variant="primary" size='lg' style={{color:"white"}}>Book A Courier</Button> */}
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item interval={3500}>
            <Image src={truckImage} fluid width="100%"></Image>
              <Carousel.Caption className='carousel-caption'>
                <h3>Delivering with Care, Every Step of the Way</h3>
                {/* <Button href='bookacourier' variant="primary" size='lg' style={{color:"white"}}>Book A Courier</Button> */}
              </Carousel.Caption>
            </Carousel.Item>
          </Carousel>
          </div>
        </div>
  )
}

export default HeroSection