import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import Container from 'react-bootstrap/Container'
import { TelephoneFill } from 'react-bootstrap-icons';
import { GeoAltFill } from 'react-bootstrap-icons'
import {EnvelopeAtFill} from 'react-bootstrap-icons';

function ContactInfo() {
  return (
    <div className='p-5'>
        <Container>
        <Row  className="justify-content-lg-center h-100  align-items-center" fluid="lg">
        <div>
            <p className='text-primary fw-bold'>How to Contact Us?</p>
            <p className='h1 fw-bold'>Contact Info</p>
        </div>
        <Row  className='contact-info'>
            <Col lg={4} className='pt-3 ps-3 pe-3'>
                <Card>
                    <Card.Body className='bg-secondary d-flex flex-column pt-3 contact-info-card-body' >  
                        <div className='text-center'>
                            <GeoAltFill size={50} style={{color:'#f15935'}}/>
                        </div>  
                        <p className='h5 strong text-center' >New Zealand Office</p>
                        <p className='text-center'>36 Paramount Drive <br/>Henderson Auckland 0612</p>
                    </Card.Body>
                </Card>
            </Col>
            <Col lg={4} className='pt-3 ps-3 pe-3'>
                <Card>
                    <Card.Body className='bg-secondary contact-card contact-info-card-body' >
                        <div className='text-center'>
                            <TelephoneFill size={50} style={{color:'#f15935'}}/>
                        </div> 
                        <p className='h5 strong text-center'>Call us on</p>
                        <p className='text-center'>+6412345678</p>
                    </Card.Body>
                </Card>
            </Col>
            <Col lg={4} className='pt-3 ps-3 pe-3'>
                <Card>
                    <Card.Body className='bg-secondary contact-card contact-info-card-body'>
                        <div className='text-center'>
                            <EnvelopeAtFill size={50} style={{color:'#f15935'}}/>
                        </div> 
                        <p className='h5 strong text-center'>Email</p>
                        <p className='text-center'>medfreightnz@gmail.com</p>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
        </Row>
        </Container>
    </div>
  )
}

export default ContactInfo