import React from 'react'
//import Button from 'react-bootstrap/Button'

function TrackOrdersSection() {
  return (
    <div className='background-image-trackorders d-flex justify-content-center align-items-center'>
      <div className='text-center'>
        <div className='h2' style={{ color: "white" }}>Fast Reliable Delivery</div>
        <div className='lead mb-3' style={{ color: "white" }}>Your Trusted Partner for Fast, Reliable and Efficient Delivery</div>
        {/* <Button href='trackorders' variant="primary" style={{ color: "white" }}>TRACK YOUR ORDER</Button> */}
      </div>
    </div>
  )
}

export default TrackOrdersSection