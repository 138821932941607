import '../custom.scss';
import React from 'react'
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/esm/Container';
import { Facebook } from 'react-bootstrap-icons';
import { Twitter } from 'react-bootstrap-icons';
import { Envelope } from 'react-bootstrap-icons';
import { Telephone } from 'react-bootstrap-icons';
import { Geo } from 'react-bootstrap-icons';

function Footer() {
  return (
    <Container className='container-lg bg-secondary ps-5 pe-5'>
      <Row>
        <Col lg={6} className='p-3'>
          <Row>
            <p className='h5 fw-bold'>Our Mission:</p>
            <p className='list-inline-item'>Our mission is to exceed your delivery expectations. We understand that timely and dependable delivery is essential for businesses to thrive and for individuals to receive their packages with ease.</p>
          </Row>
          <Row>
            <p className='h5 fw-bold'>Connect</p>
            <Col>
              <Facebook size={35} style={{color:'currentColor'}} className='me-3'/>
              <Twitter size={35} style={{color:'currentColor'}}/>
            </Col>
          </Row>
        </Col>
        <Col lg={2} className='p-3'>
            <p className='h5 text fw-bold'>Links</p>
            <Nav.Link href="aboutus">About Us</Nav.Link>
            <Nav.Link href="healthcareservices">Healthcare Services</Nav.Link>
            <Nav.Link href="urgentdelivery">Urgent Delivery</Nav.Link>
            <Nav.Link href="healthcarelogistics">Healthcare Logistics</Nav.Link>
            <Nav.Link href="contactus">Contact Us</Nav.Link>
        </Col>
        <Col lg={4}  className='p-3'>
          <p className='h5 fw-bold'>Contact</p>
          <p>Feel free to contact us through mobile, email or the contact form to get more details about our services.</p>
          <Row>
            <Col>
              <Telephone  className='me-2' size={25} style={{color:'currentColor'}}/>
              <p className='list-inline-item'>+64-220897146</p>
            </Col>
          </Row>
          <Row>
            <Col className='d-flex align-items-center'>
              <Geo  className='me-2' size={25} style={{color:'currentColor'}}/>
              <p className='list-inline-item'>36 Paramount Drive <br/>Henderson <br/> Auckland 0612</p>
            </Col>
          </Row>
          <Row>
            <Col>
              <Envelope className='me-2' size={25} style={{color:'currentColor'}}/>
              <p className='list-inline-item'>medfreightnz@gmail.com</p>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  )
}

export default Footer
