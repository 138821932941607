import './App.scss';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Home from './pages/Home';
import UrgentDelivery from './pages/UrgentDelivery';
import HealthcareServices from './pages/HealthcareServices';
import HealthcareLogistics from './pages/HealthcareLogistics';
import ContactUs from './pages/ContactUs';
import AboutUs from './pages/AboutUs';
import BookACourier from './pages/BookACourier';
import TrackOrders from './pages/TrackOrders';

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Home/>}/>
          <Route path="healthcarelogistics" element={<HealthcareLogistics/>}/>
          <Route path="urgentdelivery" element={<UrgentDelivery/>}/>
          <Route path="healthcareservices" element={<HealthcareServices/>}/>
          <Route path="contactus" element={<ContactUs/>}/>
          <Route path="aboutus" element={<AboutUs/>}/>
          <Route path="bookacourier" element={<BookACourier/>}/>
          <Route path="trackorders" element={<TrackOrders/>}/>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
