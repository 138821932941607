import '../custom.scss';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Button from 'react-bootstrap/Button';
import { useEffect, useState } from 'react';
import logoimg from '../images/MedfreightLogo.png';

function NavBar() {
  const [onContactUsPage, setOnContactUsPage] = useState(true);
  // const openQuoteWindow = () => {
  //   window.open('/getaquote', '_self');
  // };
  useEffect(()=>{
    if(window.location.pathname === "/contactus"){
      setOnContactUsPage(false);
    }
  },[])
  
  return (
    <Navbar collapseOnSelect expand="lg" className="p-3  text-white sticky-top navbar-custom ">
      <Container>
        <Navbar.Brand href="/"><img src={logoimg} height="50" className="d-inline-block align-top logo" alt="Medfright logo" /></Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ms-auto">
            <Nav.Link className='nav-link' href="/">Home</Nav.Link>
            <Nav.Link href="aboutus">About Us</Nav.Link>
            <NavDropdown title="Services" id="basic-nav-dropdown">
              <NavDropdown.Item href="healthcarelogistics">Healthcare Logistics</NavDropdown.Item>
              <NavDropdown.Item href="urgentdelivery">Urgent Delivery</NavDropdown.Item>
              <NavDropdown.Item href="healthcareservices">Healthcare Services</NavDropdown.Item>
            </NavDropdown>
            <Nav.Link href="contactus">Contact Us</Nav.Link>
            <div className={onContactUsPage? "show-quote-button" : "no-show-quote-button"}>
              <Button href="contactus" variant="primary" style={{ color: "white" }}> Get A Quote </Button>
            </div>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
export default NavBar