import { Container, Row } from "react-bootstrap";
import NavBar from '../components/NavBar'
import Footer from '../components/Footer'
import ContactUsHero from "../components/ContactUsHero";
import ContactInfo from "../components/ContactInfo";

function ContactUs() {
    return (
        <Container fluid>
            <Row>
                <NavBar/>
                <Container>
                    <Row>
                        <ContactUsHero/>
                        <ContactInfo/>
                        <Footer/>
                    </Row>
                </Container>
            </Row>
        </Container>
    )
}

export default ContactUs;
