import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import 'bootstrap/dist/css/bootstrap.min.css';

function OurFeatures() {
  return (
    <div className='bg-secondary features-padding pb-5'>
      <Container >
        <Row className="justify-content-lg-center h-100" fluid="lg">
          <Col lg>
            <p className='h5 text-primary' varient="primary">Why Choose Us?</p>
            <p className='h1'>Our Features</p>
            <p className='h6'>Our services cover a wide range of needs and are specialised in providing high-quality services to the healthcare industry. The utmost care and attention
              are paid to each shipment at our company in order to provide excellent customer service. As part of our client-centered approach, our team develops customised
              transportation solutions that address their specific needs. Our goal is to provide seamless transportation service to our clients, starting with the moment their goods
              are picked up and ending with the delivery of their goods.</p>
            <Button href="healthcareservices" varient="primary" style={{ color: "white" }}>READ MORE</Button>
          </Col>
          <Col lg>
            <Row className='justify-content-center'>
              <Card className="text-center m-2" bg={'info'} style={{ width: '18rem' }}>
                <Card.Body style={{color:'white' }}>
                  < svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" fill="currentColor" className="bi bi-diagram-3-fill" viewBox="0 0 16 16" >
                    <path d="M6 3.5A1.5 1.5 0 0 1 7.5 2h1A1.5 1.5 0 0 1 10 3.5v1A1.5 1.5 0 0 1 8.5 6v1H14a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-1 0V8h-5v.5a.5.5 0 0 1-1 0V8h-5v.5a.5.5 0 0 1-1 0v-1A.5.5 0 0 1 2 7h5.5V6A1.5 1.5 0 0 1 6 4.5v-1zm-6 8A1.5 1.5 0 0 1 1.5 10h1A1.5 1.5 0 0 1 4 11.5v1A1.5 1.5 0 0 1 2.5 14h-1A1.5 1.5 0 0 1 0 12.5v-1zm6 0A1.5 1.5 0 0 1 7.5 10h1a1.5 1.5 0 0 1 1.5 1.5v1A1.5 1.5 0 0 1 8.5 14h-1A1.5 1.5 0 0 1 6 12.5v-1zm6 0a1.5 1.5 0 0 1 1.5-1.5h1a1.5 1.5 0 0 1 1.5 1.5v1a1.5 1.5 0 0 1-1.5 1.5h-1a1.5 1.5 0 0 1-1.5-1.5v-1z" />
                  </svg>
                  <Card.Title>On-Demand Delivery</Card.Title>
                </Card.Body>
              </Card>
              <Card className="text-center m-2" bg={'info'} style={{ width: '18rem' }}>
                <Card.Body style={{color:'white' }}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" fill="currentColor" className="bi bi-door-open-fill" viewBox="0 0 16 16">
                    <path d="M1.5 15a.5.5 0 0 0 0 1h13a.5.5 0 0 0 0-1H13V2.5A1.5 1.5 0 0 0 11.5 1H11V.5a.5.5 0 0 0-.57-.495l-7 1A.5.5 0 0 0 3 1.5V15H1.5zM11 2h.5a.5.5 0 0 1 .5.5V15h-1V2zm-2.5 8c-.276 0-.5-.448-.5-1s.224-1 .5-1 .5.448.5 1-.224 1-.5 1z" />
                  </svg>
                  <Card.Title>Customisation and Scalability</Card.Title>
                </Card.Body>
              </Card>
            </Row>
          <Col>
            <Row className='justify-content-center'>
            <Card className="text-center m-2" bg={'info'} style={{ width: '18rem' }}>
                <Card.Body style={{color:'white' }}> 
                  <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" fill="currentColor" className="bi bi-file-bar-graph-fill" viewBox="0 0 16 16">
                    <path d="M12 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm-2 11.5v-6a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zm-2.5.5a.5.5 0 0 1-.5-.5v-4a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5h-1zm-3 0a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5h-1z"/>
                  </svg>
                  <Card.Title>Supply Chain and Warehouse Solutions</Card.Title>
                </Card.Body>
              </Card>
              {/* <Card className="text-center m-2" bg={'info'} style={{ width: '18rem' }}>
                <Card.Body style={{color:'white' }}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" fill="currentColor" className="bi bi-signpost-split-fill" viewBox="0 0 16 16">
                    <path d="M7 16h2V6h5a1 1 0 0 0 .8-.4l.975-1.3a.5.5 0 0 0 0-.6L14.8 2.4A1 1 0 0 0 14 2H9v-.586a1 1 0 0 0-2 0V7H2a1 1 0 0 0-.8.4L.225 8.7a.5.5 0 0 0 0 .6l.975 1.3a1 1 0 0 0 .8.4h5v5z"/>
                  </svg>
                  <Card.Title variant="info">Healthcare 3PL</Card.Title>
                </Card.Body>
              </Card> */}
            </Row>
          </Col>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default OurFeatures