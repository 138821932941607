import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import 'bootstrap/dist/css/bootstrap.min.css';
import NavBar from '../components/NavBar';
import HeroSection from '../components/HeroSection';
import OurFeatures from '../components/OurFeatures';
import TrackOrdersSection from '../components/TrackOrdersSection';
import ContactUsInfo from '../components/ContactUsInfo';
// import Testimonials from '../components/Testimonials';
import ClientLogos from '../components/ClientLogos';
import Footer from '../components/Footer';

function Home() {
  return (
    <Container fluid>
      <Row>
        <NavBar></NavBar>
        <HeroSection></HeroSection>
        <OurFeatures></OurFeatures>
        <TrackOrdersSection></TrackOrdersSection>
        <ContactUsInfo></ContactUsInfo>
        {/* <Testimonials></Testimonials> */}
        <ClientLogos></ClientLogos>
        <Footer></Footer>
      </Row>
    </Container>

  )
}

export default Home