import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import 'bootstrap/dist/css/bootstrap.min.css';
import Button from 'react-bootstrap/Button'

function ContactUsInfo() {
    return (

        <div className='Contact-Us-Info bg-secondary d-flex justify-content-center align-items-center text-center p-3'>
            <Container>
                <Row className='d-flex justify-content-center align-items-center text-center'>
                    <Col sm={9}>
                        <div className='h4' style={{ fontWeight: '800' }} >Get in touch to discuss how our dedicated healthcare logistics services can help you</div>
                    </Col>
                    <Col sm={3}>
                        <Button href="contactus" variant="primary" style={{ color: "white" }}>CONTACT US</Button>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default ContactUsInfo