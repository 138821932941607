import React from 'react'
import NavBar from '../components/NavBar'
import Footer from '../components/Footer'
import Container from 'react-bootstrap/Container'
import ContactUsInfo from '../components/ContactUsInfo'
import TrackOrdersSection from '../components/TrackOrdersSection'
import AboutUsSection from '../components/AboutUsSection'
import Row from 'react-bootstrap/Row'

function AboutUs() {
  return (
    <Container fluid>
      <Row>
        <NavBar/>
        <div className='background-image-aboutus d-flex justify-content-center align-items-start'>
          <p className='h1 fw-bold' style={{color:'black',position:'relative', top:'200px'}}>About Us</p>
        </div>
        <Container>
          <Row>
              <AboutUsSection/>
              <ContactUsInfo/>
              <TrackOrdersSection/>
              <Footer/>     
          </Row>
        </Container>
      </Row>
    </Container>
  )
}

export default AboutUs