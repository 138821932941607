import React from 'react'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import  Image from 'react-bootstrap/Image'
import craneImage from '../images/cranes.jpg';


function AboutUsSection() {
  return (
    <div>
    <Container>
        <Row  className="justify-content-lg-center h-100  align-items-center" fluid="lg">
            <Col className='m-3'>
                <p className='text-primary fw-bold'  md>Why Choose Us?</p>
                <p className='h1 fw-bold'>Our Mission</p>
                <p className='lead'>Our services cover a wide range of needs and are specialised in providing high-quality services to the healthcare 
                industry. The utmost care and attention are paid to each shipment at our company in order to provide excellent customer service. 
                As part of our client-centered approach, our team develops customised transportation solutions that address their specific needs. 
                Our goal is to provide seamless transportation service to our clients, starting with the moment their goods are picked up and ending 
                with the delivery of their goods.</p>
            </Col>
            <Col className='p-0' md>
                <Image src={craneImage} fluid width="100%" />
            </Col>
        </Row>
    </Container>
    </div>
  )
}

export default AboutUsSection