import React from 'react'
import ContactForm from "./ContactForm";
import { Col, Container, Row } from 'react-bootstrap';
function ContactUsHero() {
  return (
    <div className='background-image-contact'>
      <Container>
        <Row className='pt-5'>
          <Col lg={4} className='d-flex justify-content-start' >
            <ContactForm/>
          </Col>
          <Col lg={8}>
            <Row className='h2' style={{ color: "white" }}>Get in touch to discuss how our dedicated healthcare logistics services can help you</Row>
            <Row className='mb-3' style={{ color: "white" }}>Our services cover a wide range of needs and are specialised in providing high-quality 
            services to the Healthcare industry. The utmost care and attention are paid to each shipment at our company in order to provide excellent customer 
            service. As part of our client-centered approach, our team develops customised transportation solutions that address their specific needs. 
            Our goal is to provide seamless transportation service
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default ContactUsHero