import React from 'react'
import NavBar from '../components/NavBar'
import Footer from '../components/Footer'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'

function BookACourier() {
  return (
    <Container fluid>
      <Row>
        <NavBar/>
      </Row>
      <Row>
        <p className='h1'> coming soon... </p>
      </Row>
      <Row>
        <Footer/>
      </Row>
    </Container>
  )
}

export default BookACourier