import React from 'react';
import {Link} from 'react-router-dom';
//import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
//import Button from 'react-bootstrap/Button';
//import Card from 'react-bootstrap/Card';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BoxArrowRight } from 'react-bootstrap-icons';

function HightLightSection() {
    return (
        <div className='highlight-card'>
            <Row className="justify-content-md-center h-100 " fluid="lg">
                <Col className='bg-white p-4 rounded' sm={5} >
                    <Row>
                        <Col className='align-top' sm={4}>
                            < svg xmlns="http://www.w3.org/2000/svg" width="85" height="85" fill="currentColor" className="bi bi-diagram-3-fill" viewBox="0 0 16 16" >
                                <path d="M6 3.5A1.5 1.5 0 0 1 7.5 2h1A1.5 1.5 0 0 1 10 3.5v1A1.5 1.5 0 0 1 8.5 6v1H14a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-1 0V8h-5v.5a.5.5 0 0 1-1 0V8h-5v.5a.5.5 0 0 1-1 0v-1A.5.5 0 0 1 2 7h5.5V6A1.5 1.5 0 0 1 6 4.5v-1zm-6 8A1.5 1.5 0 0 1 1.5 10h1A1.5 1.5 0 0 1 4 11.5v1A1.5 1.5 0 0 1 2.5 14h-1A1.5 1.5 0 0 1 0 12.5v-1zm6 0A1.5 1.5 0 0 1 7.5 10h1a1.5 1.5 0 0 1 1.5 1.5v1A1.5 1.5 0 0 1 8.5 14h-1A1.5 1.5 0 0 1 6 12.5v-1zm6 0a1.5 1.5 0 0 1 1.5-1.5h1a1.5 1.5 0 0 1 1.5 1.5v1a1.5 1.5 0 0 1-1.5 1.5h-1a1.5 1.5 0 0 1-1.5-1.5v-1z" />
                            </svg>
                        </Col>
                        <Col className='align-top highlight-card-text' xs={7}>
                            <h5 className=' text-dark'>Healthcare Services</h5>
                            <p>Using our dedicated team and specialised expertise, we make sure 
                                medical supplies, medicines, and healthcare equipment reach their destinations quickly and safely.</p>
                            <div className='d-flex justify-content-end gap-2 align-items-center'>
                                <Link to="/healthcareservices" className='d-flex justify-content-end gap-2 align-items-center'>
                                    <p className='h6 m-0' >READ MORE</p>
                                    <BoxArrowRight size={25}></BoxArrowRight>
                                </Link>
                            </div>
                        </Col>
                    </Row>
                </Col>
                <Col sm={2}>
                </Col>
                <Col className='bg-white p-4 rounded' sm={5} >
                    <Row>
                        <Col className='align-top' sm={4}>
                            < svg xmlns="http://www.w3.org/2000/svg" width="85" height="85" fill="currentColor" className="bi bi-diagram-3-fill" viewBox="0 0 16 16" >
                                <path d="M6 3.5A1.5 1.5 0 0 1 7.5 2h1A1.5 1.5 0 0 1 10 3.5v1A1.5 1.5 0 0 1 8.5 6v1H14a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-1 0V8h-5v.5a.5.5 0 0 1-1 0V8h-5v.5a.5.5 0 0 1-1 0v-1A.5.5 0 0 1 2 7h5.5V6A1.5 1.5 0 0 1 6 4.5v-1zm-6 8A1.5 1.5 0 0 1 1.5 10h1A1.5 1.5 0 0 1 4 11.5v1A1.5 1.5 0 0 1 2.5 14h-1A1.5 1.5 0 0 1 0 12.5v-1zm6 0A1.5 1.5 0 0 1 7.5 10h1a1.5 1.5 0 0 1 1.5 1.5v1A1.5 1.5 0 0 1 8.5 14h-1A1.5 1.5 0 0 1 6 12.5v-1zm6 0a1.5 1.5 0 0 1 1.5-1.5h1a1.5 1.5 0 0 1 1.5 1.5v1a1.5 1.5 0 0 1-1.5 1.5h-1a1.5 1.5 0 0 1-1.5-1.5v-1z" />
                            </svg>
                        </Col>
                        <Col className='align-top highlight-card-text' sm={7}>
                            <h5 className=' text-dark'>Urgent Delivery</h5>
                            <p>Medfreight will be your trusted partner for urgent delivery services. We understand that time-sensitive shipments 
                                require swift, reliable, and secure transportation.</p>
                            <div className='d-flex justify-content-end gap-2 align-items-center'>
                                <Link to="/urgentdelivery" className='d-flex justify-content-end gap-2 align-items-center'>
                                    <p className='h6 m-0' >READ MORE</p>
                                    <BoxArrowRight size={25}></BoxArrowRight>
                                </Link>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );

}

export default HightLightSection