import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Image from 'react-bootstrap/Image'
import HeartInternational from '../images/HeartInternational.png';
import Cardia from '../images/cardia.png';
import NPX from '../images/npx.png';

function ClientLogos() {
  return (
    <div>
        <Container>
            <Row className='justify-content-lg-center h-100  align-items-center' fluid="lg">
                <Col lg={4} className='text-center pt-3'>
                    <a href="https://heartinternational.co.nz/" target='_blank' rel="noreferrer">
                        <Image src={HeartInternational} className='logo-colour'/>
                    </a>
                </Col>
                <Col lg={4} className='text-center pt-3'>
                    <a href="https://www.nxp.nz/" target='_blank' rel="noreferrer">
                        <Image src={NPX} className='logo-colour'/>
                    </a>
                </Col>
                <Col lg={4} className='text-center pt-3'>
                    <a href="https://www.cardia.nz/" target='_blank' rel="noreferrer">
                        <Image src={Cardia} className='logo-colour'/>
                    </a>
                </Col>
            </Row>
        </Container>
    </div>    

  )
}

export default ClientLogos